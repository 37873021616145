import { useNavigate } from '@solidjs/router';
import { For, onMount, Show } from 'solid-js';
import IconEmpty from '~/assets/icons/empty.svg';
import { PROPERTY_FOR_YOU_TITLE, PROPERTY_FOR_YOU_SUBTITLE, VIEW_ALL, EMPTY_DESC } from '~/assets/strings';
import { Button } from '~/components/common/buttons';
import { PropertyItem } from '~/components/property-item/PropertyItem';
import { PropertyItemSkeleton } from '~/components/property-item/PropertyItemSkeleton';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { SearchTab } from '~/pages/home/SearchTab';
import { CompanyUnitListingPresenter } from '~/presenters/CompanyUnitListingPresenter';
import { UnitParams } from '~/types/Unit';
import { GetCompanyUnitsListingUseCase } from '~/use-cases/company-unit-listing/getCompanyListingUseCase';

export const PropertyForYou = () => {
  const { t } = useLocalization();
  const parmas: UnitParams = {
    Page: 1,
    PageSize: 6,
  };
  const navigate = useNavigate();

  const viewAll = () => {
    navigate('/company-website/properties');
  };

  const { execute, isLoading } = useUseCase(GetCompanyUnitsListingUseCase);

  const { model: unitListing } = usePresenter(CompanyUnitListingPresenter);
  onMount(() => {
    execute(parmas);
  });

  return (
    <div class="relative flex w-full flex-col items-center">
      <SearchTab class="absolute left-1/2 top-[-20px] -translate-x-1/2 -translate-y-1/2" />

      <div class="mt-[152px] flex w-max-container-width flex-col items-center ">
        <div class="text-center text-[42px] font-semibold text-navy">{t(PROPERTY_FOR_YOU_TITLE)}</div>
        <div class="mt-5 w-[530px] text-center text-[18px] text-[#93A7BD]">{t(PROPERTY_FOR_YOU_SUBTITLE)}</div>

        <div class="mt-[57px] grid w-full grid-cols-3 gap-6 ">
          <Show when={!isLoading()} fallback={<For each={Array(3)}>{() => <PropertyItemSkeleton />}</For>}>
            <For each={unitListing()?.items}>{(item) => <PropertyItem data={item} />}</For>
          </Show>
        </div>
        <Show
          when={unitListing()?.items.length}
          fallback={
            <div class="flex w-full flex-col items-center justify-center">
              <img src={IconEmpty} class="mt-[137px] h-[350px] w-modal-sm" />
              <div class="text-body-sm mt-[24px] text-center text-text-level03">{t(EMPTY_DESC)}</div>
            </div>
          }>
          <div class="mt-10">
            <Button class="bg-white text-primary-color" onClick={viewAll}>
              {t(VIEW_ALL)} ⟩
            </Button>
          </div>
        </Show>
      </div>
    </div>
  );
};
