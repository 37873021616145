import { useNavigate } from '@solidjs/router';
import { For, Switch, Match, createMemo } from 'solid-js';
import { createStore } from 'solid-js/store';
import { SEARCH, HOME_TYPE, SELECT_HOME_TYPE, PRICE, SELECT_PRICE, MOVE_IN_DATE, SELECT_MOVE_IN_DATE } from '~/assets/strings';
import { Select, IconSearch, DatePicker } from '~/components/ui';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { homeTypeOptions } from '~/pages/properties/PropertiesSearchList';
import { UpdateListingSearchParamsUseCase } from '~/use-cases/company-unit-listing/updateListingSearchParamsUseCase';
import { cn } from '~/utils/classnames';

interface SearchTabProps {
  class?: string;
}

interface SearchParams {
  Price?: string;
  PropertyType?: string;
  AvailableFrom?: string;
}

const priceRangeOptions = [
  { label: '<1K', value: '0-1000' },
  { label: '1K-2K', value: '1000-2000' },
  { label: '2K-3K', value: '2000-3000' },
  { label: '3K-4K', value: '3000-4000' },
  { label: '4K-5K', value: '4000-5000' },
  { label: '>5K', value: '5000-0' },
];

export const SearchTab = (props: SearchTabProps) => {
  const { t } = useLocalization();
  const [searchParams, setSearchParams] = createStore<SearchParams>({});
  const { execute: updateParams } = useUseCase(UpdateListingSearchParamsUseCase);
  const navigate = useNavigate();

  const templates = createMemo(() => [
    {
      label: t(PRICE),
      type: 'select',
      placeholder: t(SELECT_PRICE),
      value: searchParams.Price,
      options: priceRangeOptions,
      onChange: (value: string) => setSearchParams('Price', value),
    },

    {
      label: t(HOME_TYPE),
      type: 'select',
      placeholder: t(SELECT_HOME_TYPE),
      value: searchParams.PropertyType,
      onChange: (value: string) => setSearchParams('PropertyType', value),
      options: homeTypeOptions,
    },

    {
      label: t(MOVE_IN_DATE),
      type: 'date',
      placeholder: t(SELECT_MOVE_IN_DATE),
      value: searchParams.AvailableFrom,
      onChange: (value: string | null) => setSearchParams('AvailableFrom', value ?? ''),
    },
  ]);

  const handleSearch = () => {
    const priceRange = searchParams.Price?.split('-');

    updateParams({
      MinRentalPrice: priceRange?.[0] ?? '',
      MaxRentalPrice: priceRange?.[1] ?? '',
      AvailableFrom: searchParams.AvailableFrom ?? '',
      PropertyType: searchParams.PropertyType ?? '',
    });
    navigate('/company-website/properties');
  };

  return (
    <div class={cn('justify-center', props.class)}>
      <div class="flex h-[102px] w-[1152px] items-center overflow-hidden rounded-lg bg-white shadow-[0px_8px_16px_0px_#00000014]">
        <div class="flex flex-1 justify-between px-[32px]">
          <For each={templates()}>
            {(template) => (
              <div class="flex items-center justify-between">
                <div>
                  <div class="mb-[6px] text-sm text-text-level01">{template.label}</div>
                  <Switch>
                    <Match when={template.type === 'select'}>
                      <Select
                        onChange={template.onChange!}
                        value={template.value}
                        class="border-none"
                        options={template.options!}
                        placeholder={template.placeholder}
                      />
                    </Match>
                    <Match when={template.type === 'date'}>
                      <DatePicker onChange={(value) => template.onChange!(value ?? '')} value={template.value} class="border-none" />
                    </Match>
                  </Switch>
                </div>

                <div class="ml-[23px] h-[44px] w-px bg-border-level01" />
              </div>
            )}
          </For>
        </div>

        <div
          onClick={handleSearch}
          class="flex  h-full w-[196px] cursor-pointer items-center justify-center gap-1 bg-primary-color text-[20px] text-white hover:bg-hover-color">
          <IconSearch />
          {t(SEARCH)}
        </div>
      </div>
    </div>
  );
};
